import React, { useState, useEffect } from "react";
import SocketIOContext from "./SocketProvider/SocketIOContext";
import { Dlog } from "../../utils/Dlog";

import { io } from "socket.io-client";

let env = require("../../env");
let moment = require("moment-timezone");

const APP_URL = env.REACT_SCOCKETIO_URL;

const socket = io(APP_URL + "/kiosk", {
  autoConnect: true
});

// In-Progress
// const adminsocket = io(APP_URL + "/admin", {
//   autoConnect: true
// });

export const SocketIOProvider = props => {
  const [isConnected, setIsConnected] = useState(false);
  const [socketEvents, setSocketEvents] = useState(null);
  const [socketEventsCounter, setSocketEventsCounter] = useState(0);

  const [qrCode, setQrCode] = useState("");
  const [qrCodeExpireTime, setQrCodeExpireTime] = useState("");

  const [syncTimeOffset, setSyncTimeOffset] = useState(null);

  const connect = () => {
    socket.connect();
  };

  const disconnect = () => {
    socket.disconnect();
  };

  const getSocket = () => {
    return socket;
  };

  const adminconnect = () => {
    //adminsocket.connect();
  };

  const admindisconnect = () => {
    //adminsocket.disconnect();
  };

  const getAdminSocket = () => {
    //return adminsocket;
    throw "Not Implimented";
  };

  const sendEvent = (payload, receiveEventCallback) => {
    socket.emit("event", payload);
  };

  const sendQRCodeCheck = kioskID => {
    socket.emit("qrcodecheck", { kioskId: kioskID });
  };

  const clearSocketEvents = () => {
    setSocketEvents(null);
  };

  useEffect(() => {
    console.log("SOCKET.io INITIALIZATION");

    function onConnect() {
      setIsConnected("true");
    }

    function onDisconnect() {
      setIsConnected("false");
    }

    function onEvent(value) {
      let counter = socketEventsCounter + 1; //Used to just trigger an event, even if the value is the same as previous
      setSocketEventsCounter(counter);
      let socketvalue = { data: value, counter: counter };

      //If the event is a ADVANCE action, there should be a qrcode
      //console.log("onEvent value:", value);
      if (value.action == "ADVANCE") {
        // console.log(
        //   "[onEvent] Initializing Code and Expire Time:",
        //   value.newqrcode.key,
        //   value.newqrcode.expire
        // );

        setQrCode(value.newqrcode.key);
        setQrCodeExpireTime(value.newqrcode.expire); //TODO: Check if this actual saves???
      }
      // setSocketEvents(null);

      //let socketvalue = { data: value, counter: Date.now };

      //If Event is QR CODE Update, save to context
      setSocketEvents(socketvalue);
      //console.log("SocketIOProvider onEvent socketvalue", socketvalue);

      //console.log("onEvent receiveEventFunc", receiveEventFunc);
      //if (receiveEventFunc) {
      //console.log("onEvent emit to receiveEventFunc");
      //receiveEventFunc("GOT AN EVENT");
      //}
    }

    function onUpdateQRCode(value) {
      console.log(
        "[onUpdateQRCode] Updating Code and Expire Time:",
        value.key,
        value.expire
      );
      setQrCode(value.key);
      setQrCodeExpireTime(value.expire);
    }

    function onConnectError(err) {
      console.log("[onConnectError] ", err);
    }

    //const interval = setInterval(() => onTick(), 1000);

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("event", onEvent);
    socket.on("updateqrcode", onUpdateQRCode);
    socket.on("connect_error", onConnectError);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("event", onEvent);
      socket.off("updateqrcode", onUpdateQRCode);
      socket.off("connect_error", onConnectError);
      //return () => clearInterval(interval);
    };
  }, []);

  //const [state, setState] = useState(initState);

  return (
    <SocketIOContext.Provider
      value={{
        isConnected,
        socketEvents,
        qrCode,
        qrCodeExpireTime,
        syncTimeOffset,
        connect,
        disconnect,
        getSocket,
        sendEvent,
        sendQRCodeCheck,
        clearSocketEvents,
        setQrCode,
        setQrCodeExpireTime,
        setSyncTimeOffset,
        adminconnect,
        admindisconnect,
        getAdminSocket
      }}
    >
      {props.children}
    </SocketIOContext.Provider>
  );
};
export const SocketIOConsumer = SocketIOContext.Consumer;
export default SocketIOContext;
