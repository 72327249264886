import React, { useContext, useState, useEffect } from "react";
import { Translate } from "react-i18nify";
import { kioskstyles } from "./../../../../kioskstyles";
import "./../../../../font.css";
import { IdleTimerProvider, useIdleTimerContext } from "react-idle-timer";

// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)
// https://reactjs.org/docs/refs-and-the-dom.html#refs-and-function-components
function IdleTimerDisplay(props) {
  const idleTimer = useIdleTimerContext();
  const [idletimeleft, setIdletimeleft] = useState(30);

  useEffect(() => {
    const interval = setInterval(
      () => setIdletimeleft(Math.floor(idleTimer.getRemainingTime() / 1000)),
      1000
    );

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      style={{
        position: "absolute",
        top: "0.1vh",
        right: "1vw",
        color: "black",
        display: "flex"
      }}
    >
      <div
        id="idleTimerCircle"
        class="fontSecondaryText"
        style={{
          marginLeft: "5px",
          width: "3vh",
          height: "3vh",
          fontSize: "1.5vh",
          borderRadius: "50px"
          //backgroundColor: "white"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
          }}
        >
          <span style={{ color: "white" }}>{idletimeleft}</span>
        </div>
      </div>
    </div>
  );
}

export default IdleTimerDisplay;
