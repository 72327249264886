import React from "react";
import _ from "lodash";

import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { kioskverticalstyles_2 } from "./../../../../kioskverticalstyles_2";

let env = require("./../../../../env");

// props has activeStep
export default function EnvironmentDisplay(props) {
  const theme = useTheme();

  let environment = env.REACT_APP_ENV;

  if (environment == "PROD") {
    return (
      <div>
        <div
          id="EnvironmentDisplay"
          style={{ ...kioskverticalstyles_2.envDisplay, display: "none" }}
        >
          {environment}
        </div>
      </div>
    );
  }

  return (
    <div>
      <div id="EnvironmentDisplay" style={kioskverticalstyles_2.envDisplay}>
        {environment}
      </div>
    </div>
  );
}
