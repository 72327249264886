import React, { useContext, useState, useEffect } from "react";
import _ from "lodash";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Translate, Localize, translate } from "react-i18nify";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { setTranslations, setLocale } from "react-i18nify";
import QRCode from "react-qr-code";

import LanguageButtons from "./../Shared/LanguageButtons";

import { kioskverticalstyles_2 } from "./../../../../kioskverticalstyles_2";

import {
  Button,
  Grid,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  OutlinedInput,
  ButtonGroup
} from "@mui/material";

import Logo from "./../../Components/Logo_V";

import { useTheme, createTheme, ThemeProvider } from "@mui/material/styles";

let env = require("./../../../../env");

export default function VerticalLayout(props) {
  const {
    setKioskkeyFunc,
    nextFunc,
    loading,
    errorMessage,
    isNotMobile,
    kioskkey
  } = props;

  const theme = useTheme();

  const vtheme = createTheme({
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...{
              height: "8vh",
              width: "46vw",
              fontSize: "6vh"
            }
          })
        }
      }
    }
  });

  return (
    <div style={kioskverticalstyles_2.flexBackgroundOrange}>
      <div style={kioskverticalstyles_2.logo}>
        <center>
          <Logo />
        </center>
      </div>

      <div style={kioskverticalstyles_2.instructionDiv}>
        <Translate value="application.errorscreen.errorhasoccured" />{" "}
        <Translate value="application.errorscreen.contactoperator" />
      </div>

      <div
        style={{
          ...kioskverticalstyles_2.whiteDiv,
          ...kioskverticalstyles_2.flexcenterDiv
        }}
      >
        <div style={kioskverticalstyles_2.configuration.whitecontentdiv}>
          <Typography
            variant="h5"
            style={kioskverticalstyles_2.configuration.enterkeytext}
          >
            <Translate value="application.configuration.enterkey" />
          </Typography>
          <center>
            <ThemeProvider theme={vtheme}>
              <TextField
                id="KioskKey"
                label=""
                value={kioskkey}
                style={{
                  marginBottom: "5vh"
                }}
                onChange={event => {
                  let newText = event.target.value;
                  newText = newText.toUpperCase().trim();
                  setKioskkeyFunc(newText);
                }}
              />
            </ThemeProvider>
          </center>
          <div style={kioskverticalstyles_2.configuration.buttoncontainer}>
            <div style={kioskverticalstyles_2.configuration.buttondiv}>
              {/* DEBUG BUTTONS */}
              {/* <Button
                variant="contained"
                disabled={loading}
                //onClick={navigatePrinterDebug}
                style={{ marginBottom: "3vh" }}
              >
                <span className="fontSecondaryText">
                  <Translate value="application.configuration.printerdebug" />
                </span>
              </Button> */}
              {/* END DEBUG BUTTONS */}

              <Button
                fullWidth
                variant="contained"
                disabled={loading}
                onClick={nextFunc}
                style={kioskverticalstyles_2.configuration.nextbutton}
              >
                <span className="fontSecondaryText">
                  <Translate value="application.next" />
                </span>
              </Button>
            </div>
          </div>
          <div>
            <Typography
              variant="body2"
              className="fontSecondaryText"
              style={{
                color: "red",
                marginTop: theme.spacing(0.5),
                fontSize: "2vh",
                textAlign: "center"
              }}
            >
              {errorMessage}
            </Typography>
          </div>
        </div>
      </div>
      <div style={kioskverticalstyles_2.footerDiv}>
        <LanguageButtons />
      </div>
    </div>
  );
}
