import React, { useContext } from "react";

import { kioskstyles } from "./../../../../kioskstyles";
import { kioskverticalstyles_2 } from "./../../../../kioskverticalstyles_2";
import { Translate } from "react-i18nify";

import { Button, Grid, Typography, IconButton, TextField } from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CircleButton from "./../Buttons/CircleButton_V";
import PhoneDisplay from "./PhoneDisplay";

import KioskContext from "../../../../contexts/Kiosk/KioskContext";

const steps = ["", "", "", ""];

// props has activeStep
export default function PhoneConfirm(props) {
  const { customerFirstName, customerLastName } = useContext(KioskContext);

  if (!props.showconfirmdialog) {
    return <></>;
  }

  return (
    <div
      id="space_div_and_buttons"
      style={{
        width: "100%",
        height: "39.8vh"
        //display: "flex",
        //flexDirection: "column",
        //justifyContent: "center",
        //alignContent: "space-between",
        //flexWrap: "wrap"
      }}
    >
      <div
        style={{
          //height: "30vh",
          marginBottom: "0px",
          //minHeight: "250px",
          width: "100%"
        }}
      >
        {/* <center>
          <div style={{}}>
            <PhoneDisplay value={props.phonenumber} />
          </div>
          <br />
        </center> */}
        <div
          style={{
            //marginTop: "1vh",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <div style={kioskverticalstyles_2.blackCornedDivReturningContainer}>
            <Typography
              variant="h5"
              style={{
                textAlign: "center",
                fontSize: "2.5vh"
                //marginTop: "10px"
              }}
            >
              <Translate value="application.phonenumber.returnfor" />
            </Typography>
            <br />
            <Typography
              variant="h4"
              style={{
                fontSize: "3vh"
              }}
            >
              {customerFirstName + " " + customerLastName}
            </Typography>
          </div>
        </div>
      </div>
      <div>
        <div
          style={
            {
              //marginTop: "clamp(20px, 5vh, 50px)"
            }
          }
        >
          <div
            style={{
              marginTop: "4.8vh",
              marginBottom: "2vh",
              textAlign: "center"
            }}
          >
            <span
              class="fontBold"
              //variant="h4"
              style={{
                fontSize: "3vh"
              }}
            >
              <Translate value="application.phonenumber.isthiscorrect" />
            </span>
          </div>
        </div>
        <div
          key={"telephoneButRow4"}
          style={{
            marginTop: "2vh",
            marginBottom: "1vh",
            display: "flex",
            justifyContent: "center",
            gap: "1vh"
          }}
        >
          <CircleButton
            fontSize="3vh"
            onClick={props.hideConfirmDialog}
            color="error"
          >
            <Translate value="application.no" />
          </CircleButton>
          <div
            style={{
              marginLeft: "9.2vh"
            }}
          />
          <CircleButton onClick={props.next} fontSize="3vh" color="success">
            <Translate value="application.yes" />
          </CircleButton>
        </div>
      </div>
    </div>
  );
}
