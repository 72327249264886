import React, { useContext, useState, useEffect } from "react";
import _ from "lodash";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { Translate, Localize, getLocale } from "react-i18nify";

import { kioskstyles } from "./../../kioskstyles";
import KioskStylesReact from "./../../KioskStylesReact";

import {
  Button,
  Grid,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  OutlinedInput,
  ButtonGroup
} from "@mui/material";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";

import CircleButton from "./Components/Buttons/CircleButton";
import Logo from "./Components/Logo";
import KioskStepper from "./Components/Stepper/KioskStepper";
import KioskDetail from "./Components/Shared/KioskDetail";
import IdleTimerDisplay from "./Components/Shared/IdleTimerDisplay";
import SubtitleWrapper from "./Components/Shared/PanelElements/SubtitleWrapper";
import FlexLeftPanel from "./Components/Shared/PanelElements/FlexLeftPanel";
import FlexRightPanel from "./Components/Shared/PanelElements/FlexRightPanel";

import VerticalLayout from "./Components/ThankYou/VerticalLayout";

import formatFirstNameLastInitial from "./HelperFunctions/formatFirstNameLastInitial";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useTheme } from "@mui/material/styles";

import { Dlog } from "./../../utils/Dlog";

import HttpContext from "../../contexts/HTTP/HttpContext";
import KioskContext from "../../contexts/Kiosk/KioskContext";

import { useNavigate } from "react-router-dom";

import { IdleTimerContext, IdleTimerProvider } from "react-idle-timer";

import "./../../font.css";

let env = require("./../../env");

function NumberOfBags({ authenticate }) {
  const theme = useTheme();
  const { post } = useContext(HttpContext);

  const [loading, setLoading] = useState(false);

  const {
    customerFirstName,
    customerLastName,
    customerID,
    setCustomerNameAndID,
    numBags,
    setNumBags,
    numLabels,
    setLabels,
    setAll,
    customerReset
  } = useContext(KioskContext);

  const navigate = useNavigate();

  const onIdle = async () => {
    if (env.IDLETIMER) {
      navigate("/language");
    }
  };

  const next = () => {
    Dlog("next called");
    customerReset();
    navigate("/language");
  };

  const isNotMobile = useMediaQuery("(min-width:600px)");
  const isLandscape = useMediaQuery("(orientation:landscape)");

  return (
    <VerticalLayout />
    // <IdleTimerProvider timeout={30000} onIdle={onIdle}>
    //   <Grid
    //     container
    //     rowSpacing={1}
    //     columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    //     style={kioskstyles.GridFullHeightFix}
    //   >
    //     <FlexLeftPanel isNotMobile={isNotMobile}>
    //       <div style={kioskstyles.flexLeftPanelContents}>
    //         <div style={kioskstyles.flexParentPanel}>
    //           <div style={kioskstyles.flexPanelLayoutB_TopContainer}>
    //             <Logo />
    //           </div>
    //           <div style={kioskstyles.flexPanelLayoutB_MidContainer}>
    //             <div style={kioskstyles.kioskDetails}>
    //               <div
    //                 class="fontSecondaryText"
    //                 style={kioskstyles.whiteSubtitleText}
    //               >
    //                 <Translate
    //                   value="application.thanks"
    //                   name={formatFirstNameLastInitial(
    //                     customerFirstName,
    //                     customerLastName
    //                   )}
    //                 />
    //               </div>
    //               <br />
    //               <KioskDetail />
    //               <br />
    //               <div
    //                 class="fontSecondaryText"
    //                 style={{
    //                   ...kioskstyles.whiteBodyText,
    //                   ...kioskstyles.kioskDetailsSpaceBetween
    //                 }}
    //               >
    //                 <Translate
    //                   value="application.kioskdetail.returningnumbags"
    //                   count={numBags}
    //                 />
    //               </div>
    //               <br />
    //               <div
    //                 class="fontSecondaryText"
    //                 style={{
    //                   ...kioskstyles.whiteBodyText,
    //                   ...kioskstyles.kioskDetailsSpaceBetween
    //                 }}
    //               >
    //                 <Translate
    //                   value="application.kioskdetail.returningnumlabels"
    //                   count={numLabels}
    //                 />
    //               </div>
    //             </div>
    //           </div>
    //           <div
    //             style={{
    //               ...kioskstyles.flexPanelLayoutB_BotContainer,
    //               alignItems: "start"
    //             }}
    //           >
    //             {" "}
    //             <Button
    //               variant="contained"
    //               disabled={loading}
    //               onClick={next}
    //               style={{
    //                 ...kioskstyles.textButtons,
    //                 borderRadius: "8px",
    //                 marginBottom: "0px"
    //               }}
    //             >
    //               <span class="fontSecondaryText">
    //                 <Translate value="application.thankyou.finish" />
    //               </span>
    //             </Button>
    //           </div>
    //         </div>
    //       </div>
    //     </FlexLeftPanel>
    //     <FlexRightPanel isNotMobile={isNotMobile}>
    //       <div
    //         style={{
    //           ...kioskstyles.flexRightPanelContents,
    //           display: "flex",
    //           flexDirection: "column",
    //           height: "100%"
    //         }}
    //       >
    //         <div style={kioskstyles.flexParentPanel}>
    //           <div style={kioskstyles.flexPanelLayoutB_TopContainer}>
    //             <SubtitleWrapper isNotMobile={isNotMobile}>
    //               <Translate value="application.thankyou.thankyou" />
    //             </SubtitleWrapper>
    //           </div>
    //           <br />
    //           <div>
    //             <center>
    //               <CheckBoxOutlinedIcon
    //                 style={{ fontSize: "clamp(30px, 8vh, 120px)" }}
    //                 // style={{ fontSize: "clamp(30px, 5.5vw, 120px)" }}
    //               />
    //             </center>
    //           </div>
    //           <span
    //             class="fontNormal"
    //             style={{
    //               ...kioskstyles.blackBodyText
    //             }}
    //           >
    //             <b>
    //               <Translate value="application.thankyou.labelyourbags" />
    //             </b>{" "}
    //             <span style={kioskstyles.HelperTextColor}>
    //               <Translate value="application.thankyou.attachlabeltobags" />
    //             </span>
    //           </span>
    //           <br />
    //           <br />
    //           <div>
    //             <center>
    //               <ArrowDropDownCircleIcon
    //                 style={{ fontSize: "clamp(30px, 8vh, 120px)" }}
    //               />
    //             </center>
    //           </div>

    //           <span
    //             class="fontNormal"
    //             style={{
    //               ...kioskstyles.blackBodyText
    //             }}
    //           >
    //             <b>
    //               <Translate value="application.thankyou.dropindroparea" />
    //             </b>{" "}
    //             <span style={kioskstyles.HelperTextColor}>
    //               <Translate value="application.thankyou.placeindesignatedarea" />
    //             </span>
    //           </span>
    //           <br />
    //           <br />
    //           <div>
    //             <center>
    //               <SentimentSatisfiedAltIcon
    //                 style={{ fontSize: "clamp(30px, 8vh, 120px)" }}
    //               />
    //             </center>
    //           </div>

    //           <span
    //             class="fontNormal"
    //             style={{
    //               ...kioskstyles.blackBodyText
    //             }}
    //           >
    //             <b>
    //               <Translate value="application.thankyou.thatsit" />
    //             </b>{" "}
    //             <span style={kioskstyles.HelperTextColor}>
    //               <Translate value="application.thankyou.refundcredited" />
    //             </span>
    //           </span>
    //         </div>
    //       </div>
    //     </FlexRightPanel>
    //   </Grid>
    //   <IdleTimerDisplay />
    // </IdleTimerProvider>
  );
}

export default NumberOfBags;
