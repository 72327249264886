import React from "react";
import _ from "lodash";

import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import EnvironmentDisplay from "./Logo/EnvironmentDisplay";
import VersionDisplay from "./Logo/VersionDisplay";
import { kioskstyles } from "./../../../kioskstyles";

let env = require("./../../../env");

// props has activeStep
export default function Logo(props) {
  const theme = useTheme();

  let environment_text = env.REACT_APP_ENV;
  if (env.REACT_APP_ENV == "PROD") {
    environment_text = "";
  }

  return (
    <div
      style={
        {
          //maxWidth: "700px"
        }
      }
    >
      {/* <Typography
        variant="body2"
        style={{
          color: "yellow",
          //marginTop: theme.spacing(5),
          // paddingRight: "clamp(0px, 27vw, 416px)",
          //paddingRight: "clamp(0px, 27vw, 446px)",
          fontSize: "clamp(12px, 3vw, 36px)",
          //height: "10px",
          fontWeight: "bold",
          //position: "relative",
          top: "0px",
          left: "0px"
        }}
      >
        {environment_text}
      </Typography> */}
      <EnvironmentDisplay />
      <img
        src="/img/consignaction-logo-cropped.png"
        style={{
          width: "80%",
          position: "relative",
          top: "0px",
          left: "0px"
        }}
        alt="Consignaction Logo"
      ></img>
      <VersionDisplay />
    </div>
  );
}
