const flexBackground = {
  background: "rgb(60, 183, 76)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  //justifyContent: "center",
  height: "100vh",
  width: "100vw",
  paddingLeft: "0px",
  paddingTop: "0px" //Fix for Grid leaving space at the bottom
  //maxWidth: "800px",
  //marginLeft: "auto"
};

const flexBackgroundOrange = {
  backgroundColor: "orange",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  //justifyContent: "center",
  height: "100vh",
  width: "100vw",
  paddingLeft: "0px",
  paddingTop: "0px" //Fix for Grid leaving space at the bottom
  //maxWidth: "800px",
  //marginLeft: "auto"
};

const logo = {
  marginTop: "3.6vh",
  marginBottom: "2.3vh",
  height: "5.5vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginLeft: "8.5vw",
  marginRight: "8.5vw"
};

const instructionDiv = {
  marginBottom: "3.3vh",
  height: "7.5vh",
  color: "white",
  //fontSize: "2.4vh",
  fontSize: "2vh",
  width: "83vw",
  textAlign: "center"
};

const instructionVerticallyAlignDiv = {
  position: "relative",
  top: "50%",
  transform: "translateY(-50%)"
};

const whiteDiv = {
  height: "69.7vh",
  width: "100vw",
  backgroundColor: "white"
};

const flexcenterDiv = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
  //justifyContent: "space-around"
};

const footerDiv = {
  height: "8.2vh",
  width: "83vw",
  display: "flex",
  flexDirection: "column",
  //alignItems: "center"
  justifyContent: "center"
};

/* flexbox centered colmuns */

const biRowNumberSelect = {
  //marginBottom: "2vw",
  display: "flex",
  justifyContent: "center",
  gap: "2vw"
};

const InfoText = {
  fontSize: "2.3vh"
};

const blackCornedDivReturningContainer = {
  borderRadius: "1.5vh",
  backgroundColor: "black",
  color: "white",
  width: "42vw",
  height: "100%",
  padding: "3.1vh 4vh 3.1vh 4vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center"
};

const configuration = {
  instructionDiv: {
    margin: "auto"
  },
  whitecontentdiv: {
    width: "83vw",
    height: "100%",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column"
  },
  enterkeytext: {
    fontSize: "4vh",
    textAlign: "center",
    marginBottom: "5vh"
  },
  buttoncontainer: {
    width: "100%",
    height: "auto",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column"
  },
  buttondiv: {
    width: "36vw",
    marginLeft: "auto",
    marginRight: "auto"
  },
  nextbutton: {
    height: "7vh",
    marginBottom: "0px",
    fontSize: "2vh"
  }
};

const languageselect = {
  flexBackground: {
    background: "rgb(60, 183, 76)",
    background:
      "linear-gradient(130deg, rgba(60, 183, 76) 0%, rgba(60, 183, 76) 60%, rgba(83, 192, 98,1) 80%, rgba(105, 200, 116,1) 100%)",
    //backgroundImage: "url(/img/BottlePattern.svg)",  //as per https://gitlab.com/tgayef/lt3-kiosk/-/issues/28
    //backgroundSize: "310px 310px",
    //backgroundRepeat: "repeat",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    //justifyContent: "center",
    height: "100vh",
    width: "100vw",
    paddingLeft: "0px",
    paddingTop: "0px" //Fix for Grid leaving space at the bottom
    //maxWidth: "800px",
    //marginLeft: "auto"
  },
  logo: {
    marginTop: "6vh",
    marginBottom: "5vh",
    height: "6vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "8.5vw",
    marginRight: "8.5vw"
  },
  qrdiv: {
    marginBottom: "4.6vh",
    height: "24vh",
    width: "83vw",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: "8.5vw",
    marginRight: "8.5vw",
    borderRadius: "1.8vh",
    backgroundColor: "black",
    color: "white"
  },
  qrelement: {
    marginLeft: "3vh",
    height: "18vh",
    width: "18vh",
    borderRadius: "0.4vh",
    backgroundColor: "white",
    display: "flex"
  },
  qrtextelement: {
    width: "45vw",
    height: "100%",
    marginRight: "3vw",
    marginLeft: "2vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  title: {
    //marginBottom: "5vh",
    marginBottom: "7.5vh",
    height: "14.9vh",
    width: "83vw",
    //fontSize: "4.7vh",
    fontSize: "6.7vh",
    //fontSize: "7.5vh",
    textAlign: "center",
    color: "white"
  },
  button: {
    marginBottom: "6.8vh",
    height: "7.3vh",
    width: "83vw"
    //fontSize: "4.7vh",
    //color: "white"
  },
  languagediv: {
    //marginBottom: "6.8vh",
    width: "83vw"
    //fontSize: "4.7vh",
    //color: "white"
  }
};

const languagebutton = {
  languagebuttonwidget: {
    margin: "0vh 0.6vh 0vh 0.6vh",
    // paddingLeft: "2vw",
    // paddingRight: "2vw",
    padding: "1vw 3.5vw",
    height: "6vh",
    //minHeight: "23px",
    textTransform: "capitalize",
    //fontSize: "1.6vw"
    fontSize: "2.2vh",
    borderRadius: "0.4vh"
  }
};

const envDisplay = {
  position: "relative",
  height: "0px",
  color: "yellow",
  fontSize: "1.9vh",
  top: "-2.9vh", //calc(-1.9vh - 1vh)
  fontWeight: "bold"
};

export const kioskverticalstyles_2 = {
  flexBackground: flexBackground,
  flexBackgroundOrange: flexBackgroundOrange,
  logo: logo,
  instructionDiv: instructionDiv,
  instructionVerticallyAlignDiv: instructionVerticallyAlignDiv,
  whiteDiv: whiteDiv,
  flexcenterDiv: flexcenterDiv,
  footerDiv: footerDiv,
  biRowNumberSelect: biRowNumberSelect,
  InfoText: InfoText,
  blackCornedDivReturningContainer: blackCornedDivReturningContainer,
  configuration: configuration,
  languageselect: languageselect,
  languagebutton: languagebutton,
  envDisplay: envDisplay
  // qrdiv: qrdiv,
  // qrelement: qrelement,
  // qrtextelement: qrtextelement,
  // title: title,
  // button: button,
  // languagediv: languagediv,
  // languagebuttonwidget: languagebuttonwidget
};
