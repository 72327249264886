import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)
// https://reactjs.org/docs/refs-and-the-dom.html#refs-and-function-components
function CircleButton(props) {
  /* eslint-disable */
  const ColorButton = styled(Button)({
    borderRadius: "50%",
    fontSize: "clamp(18px, 2.4vi, 36px)",
    padding: "2vh",
    height: "clamp(65px, 11.5vmin, 100px)",
    width: "clamp(65px, 11.5vmin, 100px)"
  });
  /* eslint-enable */

  return (
    <ColorButton
      variant="contained"
      color={props.color}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </ColorButton>
  );
}

export default CircleButton;
