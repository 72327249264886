import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";

import {
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  colors,
  Paper,
  IconButton,
  TextField
} from "@mui/material";

import { useTheme } from "@mui/material/styles";

import RefreshIcon from "@mui/icons-material/Refresh";

import ContainerLoading from "../Loading/ContainerLoading";

import { formatDate } from "../../utils/misc";

import UpdateLogRow from "./SyncUpdateLogs/UpdateLogRow";

import { KEY_NAME_REGEX } from "../../constants";

import HttpContext from "../../contexts/HTTP/HttpContext";

function APIKeys() {
  const theme = useTheme();
  const { get } = useContext(HttpContext);

  const [loading, setLoading] = useState(true);

  const [allLogs, setAllLogs] = useState([]);

  const loadData = async () => {
    setLoading(true);

    //Load data
    const res2 = await get("/kiosk/UpdateLogs");
    if (res2.status === 200) {
      const subscriptionData = _.get(res2, "data");

      setAllLogs(subscriptionData.synclogs || []);
      console.log("loadDetails", subscriptionData.synclogs);
    }

    setLoading(false);
  };

  const loadDetails = async id => {};

  useEffect(() => {
    (async () => {
      await loadData();
    })();
  }, []);

  return (
    <Grid
      style={{
        margin: theme.spacing(2),
        padding: theme.spacing(3),
        width: "100%",
        backgroundColor: colors.grey[100],
        borderRadius: "10px"
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h4">Sync Logs [{allLogs.length}]</Typography>
          <Typography>Sync updates from CA App</Typography>
        </div>
        <div>
          <Button
            variant="contained"
            onClick={() => loadData()}
            disabled={loading}
          >
            <RefreshIcon style={{ marginRight: theme.spacing(1) }} />
            Refresh
          </Button>
        </div>
      </div>
      <Divider
        style={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(2) }}
      />
      <ContainerLoading show={loading} />
      <TableContainer>
        <Table
          style={{
            marginTop: theme.spacing(2),
            display: loading ? "none" : null
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Start</TableCell>
              <TableCell align="left">Completed</TableCell>
              <TableCell align="left">inboundCode</TableCell>
              <TableCell align="center">inboundData</TableCell>
              <TableCell align="left">error</TableCell>
              <TableCell align="center">status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allLogs.map(row => (
              <UpdateLogRow row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

export default APIKeys;

function isKeyNameValid(name) {
  if (!name) return false;

  return KEY_NAME_REGEX.test(name);
}

function isKeyNameDuplicate(keys, name) {
  return keys.map(key => key.name).includes(name);
}

function getKeyNameHelperText(keys, name) {
  if (!isKeyNameValid(name)) {
    return "Invalid name";
  }

  if (isKeyNameDuplicate(keys, name)) {
    return "Name already in use";
  }

  return "";
}
