import moment from "moment-timezone";
let env = require("./../env.js");

const timezone = env.REACT_APP_TIMEZONE;

export const formatDate = (date, format) => {
  return moment(date)
    .tz(timezone)
    .format(format);
};
