import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)
// https://reactjs.org/docs/refs-and-the-dom.html#refs-and-function-components
function CircleButton(props) {
  /* eslint-disable */

  const size = "8.5vh";

  const ColorButton = styled(Button)({
    borderRadius: "50%",
    fontSize: props.fontSize ?? "4vh",
    padding: "2vh",
    height: size,
    width: size,
    minWidth: size
  });
  /* eslint-enable */

  return (
    <ColorButton
      variant="contained"
      color={props.color}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </ColorButton>
  );
}

export default CircleButton;
