//Depreicated, test and remove

const printonly = {
  [`@media print`]: {
    display: "none"
  },
  [`@media screen`]: {
    display: "block",
    flexDirection: "row",
    width: "100%"
  }
};

export const printstyles = {
  printonly: printonly
};
