import React, { useContext, useState, useEffect } from "react";
import _ from "lodash";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Translate, Localize, translate } from "react-i18nify";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { setTranslations, setLocale } from "react-i18nify";
//import QRCode from "react-qr-code";
import { QRCode } from "react-qrcode-logo";

import LanguageButtons from "./../Shared/LanguageButtons";

import { kioskverticalstyles_2 } from "./../../../../kioskverticalstyles_2";

import SocketIOContext from "../../../../contexts/Kiosk/SocketProvider/SocketIOContext";

import {
  Button,
  Grid,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  OutlinedInput,
  ButtonGroup
} from "@mui/material";

import Logo from "./../../Components/Logo";

import { useTheme } from "@mui/material/styles";

let env = require("./../../../../env");

export default function VerticalLayout(props) {
  const {
    setKioskkeyFunc,
    nextFunc,
    loading,
    errorMessage,
    isNotMobile,
    kioskkey
  } = props;

  const {
    isConnected,
    socketEvents,
    qrCode,
    connect,
    disconnect,
    getSocket,
    sendEvent,
    clearSocketEvents
  } = useContext(SocketIOContext);

  const theme = useTheme();

  return (
    <div style={kioskverticalstyles_2.languageselect.flexBackground}>
      <div style={kioskverticalstyles_2.languageselect.logo}>
        <center>
          <Logo />
        </center>
      </div>

      <div class="fontBold" style={kioskverticalstyles_2.languageselect.title}>
        <Translate value="application.languageselect.kioskname" />
      </div>

      <div style={kioskverticalstyles_2.languageselect.button}>
        <Button
          variant="outlined"
          fullWidth
          style={{
            backgroundColor: "white",
            color: "rgb(39, 167, 55)",
            fontSize: "3vh",
            border: "0px solid white"
          }}
          onClick={nextFunc}
        >
          <Translate value="application.languageselect.presstostart" />
        </Button>
      </div>

      <div style={kioskverticalstyles_2.languageselect.qrdiv}>
        <div style={kioskverticalstyles_2.languageselect.qrelement}>
          <QRCode
            style={{
              height: "auto",
              width: "auto",
              maxWidth: "100%",
              maxHeight: "100%"
            }}
            size="600"
            fgColor="#27a737"
            logoImage="/img/ca.svg"
            qrStyle="dots"
            removeQrCodeBehindLogo="true"
            eyeRadius={{ outer: 5, inner: 1 }}
            logoPadding="3"
            //value="https://app.consignaction.com/qs/{{qrCode}}"
            value={env.CAAPP_URL + "/qs/" + qrCode}
          />
        </div>
        <div style={kioskverticalstyles_2.languageselect.qrtextelement}>
          <div
            class="fontBold"
            style={{
              fontSize: "2.8vh",
              textAlign: "center",
              marginBottom: "2vh"
            }}
          >
            <Translate value="application.languageselect.quickstart" />
          </div>
          <div
            class="fontNormal"
            style={{ fontSize: "1.7vh", textAlign: "center" }}
          >
            <Translate value="application.languageselect.scanwithapp" />
          </div>
        </div>
      </div>

      <div style={kioskverticalstyles_2.languageselect.languagediv}>
        <LanguageButtons />
      </div>
    </div>
  );
}
