import React, { useContext, useState } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { Translate, Localize, getLocale } from "react-i18nify";
import axios from "axios";

import { kioskstyles } from "./../../kioskstyles";

import {
  Button,
  Grid,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  OutlinedInput,
  ButtonGroup
} from "@mui/material";

import CircleButton from "./Components/Buttons/CircleButton";
import Logo from "./Components/Logo";
import KioskStepper from "./Components/Stepper/KioskStepper";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useTheme } from "@mui/material/styles";

import HttpContext from "../../contexts/HTTP/HttpContext";
import KioskContext from "../../contexts/Kiosk/KioskContext";

import { useNavigate } from "react-router-dom";

import { Capacitor } from "@capacitor/core";
import EpsonPrinter from "./../../plugin/EsponPrinter";

let env = require("./../../env.js");

const API_ENDPOINT = env.REACT_APP_API_ENDPOINT;

const ERRORS = {
  400: "An error occurred while processing your login",
  401: "Invalid credentials provided",
  404: "Invalid credentials provided"
};

function PrinterDebug({ authenticate }) {
  const theme = useTheme();
  const { post, get } = useContext(HttpContext);

  const [result, setResult] = useState("");
  const [errorResult, setErrorResult] = useState("");

  const navigate = useNavigate();

  // START Plugin Functions

  const checkPlugin = async () => {
    setResult("");
    setErrorResult("");

    //Check if Plugin isAvailable
    const androidEsponPrinter = Capacitor.isPluginAvailable("EpsonPrinter");

    if (!androidEsponPrinter) {
      setErrorResult("EpsonPrinter plugin unvavailable. Probably not android");
      return;
    }

    setResult("EsponPrinterPlugin isAvailable:" + androidEsponPrinter);
  };

  const testEcho = async () => {
    setResult("");
    setErrorResult("");

    try {
      let value = await EpsonPrinter.echo({ value: "Hello World!" });
      setResult(value.value.toString());
    } catch (e) {
      setErrorResult("CATCHERROR: " + e.toString());
    }
  };

  const testStartFinderUSBPrinters = async () => {
    setResult("");
    setErrorResult("");

    try {
      let value = await EpsonPrinter.startFinderUSBPrinters();
      console.log(value);
      setResult(value.value.toString());
    } catch (e) {
      setErrorResult("CATCHERROR: " + e.toString());
    }
  };

  const testGetFoundPrinters = async () => {
    setResult("");
    setErrorResult("");

    try {
      let value = await EpsonPrinter.getFoundPrinters();
      console.log(value);
      setResult(value.value.toString());
    } catch (e) {
      setErrorResult("CATCHERROR: " + e.toString());
    }
  };

  // const testPrintT88VII = async () => {
  //   setResult("");
  //   setErrorResult("");

  //   let bagtags = ["CA20240320GBY100NXVB2016", "CA20240320GBY100NXVB2017"];

  //   try {
  //     let value = await EpsonPrinter.testPrintT88VII({ bagtags: bagtags });
  //     console.log(value);
  //     setResult(value.value.toString());
  //   } catch (e) {
  //     setErrorResult("CATCHERROR: " + e.toString());
  //   }
  // };

  // const testPrintL90 = async () => {
  //   setResult("");
  //   setErrorResult("");

  //   let bagtags = ["CA20240320GBY100NXVB2016", "CA20240320GBY100NXVB2017"];

  //   try {
  //     let value = await EpsonPrinter.testPrintL90({ bagtags: bagtags });
  //     console.log(value);
  //     setResult(value.value.toString());
  //   } catch (e) {
  //     setErrorResult("CATCHERROR: " + e.toString());
  //   }
  // };

  const testPrintWithPlugin = async () => {
    setResult("");
    setErrorResult("");

    let bagtags = ["CA20240410TST100NXVB2016"];

    try {
      let value = await EpsonPrinter.PrintWithSelectedPrinter({
        bagtags: bagtags
      });
      console.log(value);
      setResult(value.value.toString());
    } catch (e) {
      setErrorResult("CATCHERROR: " + e.toString());
    }
  };

  const testAndDisplay = async testFunc => {
    setResult("");
    setErrorResult("");

    try {
      let value = await testFunc();
      console.log(value);
      setResult(value.value.toString());
    } catch (e) {
      setErrorResult("CATCHERROR: " + e.toString());
    }
  };

  // END Plugin Functions

  const returnToConfig = async () => {
    navigate("/configuration");
  };

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <div
        style={{
          marginLeft: "3vw",
          marginRight: "3vw",
          marginTop: "2vh",
          width: "100%"
        }}
      >
        {/* <button type="button" onClick={checkPlugin}>
          check Plugin
        </button>
        <button type="button" onClick={testEcho}>
          test Echo
        </button>
        <button type="button" onClick={testStartFinderUSBPrinters}>
          test startFinderUSBPrinters
        </button>

        <button type="button" onClick={testGetFoundPrinters}>
          test getFoundPrinters
        </button>

        <button
          type="button"
          onClick={() => {
            testAndDisplay(EpsonPrinter.getLastPrintStatus);
          }}
        >
          getLastPrintStatus
        </button>

        <button
          type="button"
          onClick={() => {
            testAndDisplay(EpsonPrinter.initializePrinterPosition);
          }}
        >
          initializePrinterPosition
        </button>

        <button
          type="button"
          onClick={() => {
            testAndDisplay(EpsonPrinter.setQRCODEModel1);
          }}
        >
          setQRCODEModel1
        </button>

        <button
          type="button"
          onClick={() => {
            testAndDisplay(EpsonPrinter.setQRCODEModel2);
          }}
        >
          setQRCODEModel2
        </button>

        <button
          type="button"
          onClick={() => {
            testAndDisplay(EpsonPrinter.setECL0);
          }}
        >
          setECL0
        </button>

        <button
          type="button"
          onClick={() => {
            testAndDisplay(EpsonPrinter.setECLL);
          }}
        >
          setECLL
        </button>

        <button
          type="button"
          onClick={() => {
            testAndDisplay(EpsonPrinter.setECLM);
          }}
        >
          setECLM
        </button>

        <button
          type="button"
          onClick={() => {
            testAndDisplay(EpsonPrinter.setECLQ);
          }}
        >
          setECLQ
        </button>

        <button
          type="button"
          onClick={() => {
            testAndDisplay(EpsonPrinter.setECLH);
          }}
        >
          setECLH
        </button> */}
        {/*
        <button
          type="button"
          onClick={testAndDisplay(EpsonPrinter.setQRCODEModel1)}
        >
          setQRCODEModel1
        </button>
        
        <button
          type="button"
          onClick={testAndDisplay(EpsonPrinter.setQRCODEModel2)}
        >
          setQRCODEModel2
        </button>
        <button type="button" onClick={testAndDisplay(EpsonPrinter.setECL0)}>
          setECL0
        </button>
        <button type="button" onClick={testAndDisplay(EpsonPrinter.setECLL)}>
          setECLL
        </button>
        <button type="button" onClick={testAndDisplay(EpsonPrinter.setECLM)}>
          setECLM
        </button>
        <button type="button" onClick={testAndDisplay(EpsonPrinter.setECLQ)}>
          setECLQ
        </button>
        <button type="button" onClick={testAndDisplay(EpsonPrinter.setECLH)}>
          setECLH
        </button> */}

        {/* <button type="button" onClick={testPrintWithPlugin}>
          testPrintWithPlugin
        </button> */}

        <div style={{ marginBottom: "12px" }}>
          <Button
            variant="outlined"
            style={{ textTransform: "capitalize" }}
            onClick={testPrintWithPlugin}
          >
            <Translate value="application.printerdebug.printtestlabel" />
          </Button>
        </div>
        <br />
        <Typography>Result:</Typography>
        {/* <div
          style={{
            backgroundColor: "lightgrey",
            margin: "0px",
            padding: "10px",
            borderRadius: "12px"
          }}
        >
          <Typography>{result}</Typography>
        </div> */}
        <RoundedDisplay text={result} />
        <br />
        <Typography>Error:</Typography>
        {/* <div
          style={{
            backgroundColor: "lightgrey",
            margin: "0px",
            padding: "10px",
            borderRadius: "12px"
          }}
        >
          <Typography>{errorResult}</Typography>
        </div> */}
        <RoundedDisplay text={errorResult} />
        <br />
        <Button
          variant="contained"
          style={{ textTransform: "capitalize" }}
          onClick={returnToConfig}
        >
          <Translate value="application.printerdebug.returntoconfigure" />
        </Button>
      </div>
    </Grid>
  );
}

function RoundedDisplay(props) {
  if (props.text == "") {
    return <></>;
  }

  return (
    <div
      style={{
        backgroundColor: "lightgrey",
        margin: "0px",
        padding: "10px",
        borderRadius: "12px"
      }}
    >
      <Typography>{props.text}</Typography>
    </div>
  );
}

export default PrinterDebug;
