import React from "react";
import { kioskstyles } from "./../../../../../kioskstyles";
import { Typography, Grid } from "@mui/material";

function FlexRightPanel(props) {
  const isNotMobile = props.isNotMobile;

  if (isNotMobile) {
    return (
      <Grid item xs={12} sm={6} style={kioskstyles.flexRightPanel}>
        {props.children}
      </Grid>
    );
  } else {
    return (
      <Grid item xs={12} sm={6} style={kioskstyles.flexRightPanelMobile}>
        {props.children}
      </Grid>
    );
  }
}

export default FlexRightPanel;
