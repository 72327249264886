import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import DetailsRow from "./DetailsDialog/DetailsRow";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function DetailsDialog(props) {
  const {
    open,
    title,
    disableClose,
    disableConfirm,
    handleClose,
    closeButtonName,
    handleConfirm,
    confirmButtonName,
    subscriptionData
  } = props;

  function createData(name, calories, fat, carbs) {
    return {
      name,
      calories,
      fat,
      carbs,
      history: [
        {
          date: "2020-01-05",
          customerId: "11091700",
          amount: 3
        },
        {
          date: "2020-01-02",
          customerId: "Anonymous",
          amount: 1
        }
      ]
    };
  }

  function TableContents(props) {
    const { subscriptionData } = props;

    if (subscriptionData == null || subscriptionData.kioskKeys == null) {
      return <></>;
    }

    return subscriptionData.kioskKeys.map(row => (
      <DetailsRow key={row._id} row={row} />
    ));
  }

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.name}
          </TableCell>
          <TableCell align="right">{row.calories}</TableCell>
          <TableCell align="right">{row.fat}</TableCell>
          <TableCell align="right">{row.carbs}</TableCell>
        </TableRow>
        {/* <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  History
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Customer</TableCell>
                      <TableCell align="right">Amount</TableCell>
                      <TableCell align="right">Total price ($)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.history.map(historyRow => (
                      <TableRow key={historyRow.date}>
                        <TableCell component="th" scope="row">
                          {historyRow.date}
                        </TableCell>
                        <TableCell>{historyRow.customerId}</TableCell>
                        <TableCell align="right">{historyRow.amount}</TableCell>
                        <TableCell align="right">
                          {Math.round(historyRow.amount * row.price * 100) /
                            100}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow> */}
      </React.Fragment>
    );
  }

  Row.propTypes = {
    row: PropTypes.shape({
      calories: PropTypes.number.isRequired,
      carbs: PropTypes.number.isRequired,
      fat: PropTypes.number.isRequired,
      history: PropTypes.arrayOf(
        PropTypes.shape({
          amount: PropTypes.number.isRequired,
          customerId: PropTypes.string.isRequired,
          date: PropTypes.string.isRequired
        })
      ).isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      protein: PropTypes.number.isRequired
    }).isRequired
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Details for LOCATIONCODE</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>KioskID</TableCell>
                <TableCell>IP</TableCell>
                <TableCell>Last Accessed</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableContents subscriptionData={subscriptionData} />
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        {handleConfirm && (
          <Button disabled={disableConfirm} onClick={handleConfirm}>
            {confirmButtonName || "Confirm"}
          </Button>
        )}
        <Button disabled={disableClose} color="secondary" onClick={handleClose}>
          {closeButtonName || "Close"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DetailsDialog;
