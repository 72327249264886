import * as React from "react";

import { Typography } from "@mui/material";

function LocationObjectDisplay(props) {
  const { Locationobject } = props;

  console.log("LocationObjectDisplay Locationobject", Locationobject);

  let name = "";
  let address = "";

  if (Locationobject !== null) {
    name = Locationobject.name;
    address = Locationobject.address;
  }

  return (
    <div>
      <p>
        <Typography>{name}</Typography>
      </p>
      <p>
        <Typography>{address}</Typography>
      </p>
    </div>
  );
}

export default LocationObjectDisplay;
