import React, { useEffect, useContext, useState } from "react";
import _ from "lodash";
import { Route, Routes, Navigate } from "react-router-dom";

let env = require("./../../../env");

//Component is the element
// redirect is a string route to redirect to, if its prod
function NonProdRoute(props) {
  //console.log(props.Component);
  const { Component, redirect } = props;

  if (env.REACT_APP_ENV == "PROD") {
    return <Navigate to={redirect} />;
  }

  return Component;
}

export default NonProdRoute;
