import React from "react";
import { styled } from "@mui/material/styles";
import StepButton from "@mui/material/StepButton";

// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)
// https://reactjs.org/docs/refs-and-the-dom.html#refs-and-function-components
function StepperButton(props) {
  /* eslint-disable */
  const StepperButton = styled(StepButton)({});
  /* eslint-enable */

  return (
    <StepperButton variant="contained" color={props.color} style={props.style}>
      {props.children}
    </StepperButton>
  );
}

export default StepperButton;
