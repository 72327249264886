import { createContext } from "react";

const SocketIOContext = createContext({
  isConnected: "false",
  socketEvents: null,
  qrCode: "",
  qrCodeExpireTime: "",
  syncTimeOffset: null,
  connect: () => {},
  disconnect: () => {},
  getSocket: () => {},
  sendEvent: () => {},
  sendQRCodeCheck: () => {},
  clearSocketEvents: () => {},
  setQrCode: () => {},
  setQrCodeExpireTime: () => {},
  setSyncTimeOffset: () => {},
  adminconnect: () => {},
  admindisconnect: () => {},
  getAdminSocket: () => {}
});

export default SocketIOContext;
