import React, { useContext, useState, useEffect } from "react";
import _ from "lodash";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Translate, Localize, translate } from "react-i18nify";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { setTranslations, setLocale } from "react-i18nify";
import QRCode from "react-qr-code";

import { kioskstyles } from "./../../../../kioskstyles";

import {
  Button,
  Grid,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  OutlinedInput,
  ButtonGroup
} from "@mui/material";

import CircleButton from "./../../Components/Buttons/CircleButton";
import Logo from "./../../Components/Logo";
import LanguageButtons from "./../../Components/Shared/LanguageButtons";
import SubtitleWrapper from "./../../Components/Shared/PanelElements/SubtitleWrapper";
import FlexLeftPanel from "./../../Components/Shared/PanelElements/FlexLeftPanel";
import FlexRightPanel from "./../../Components/Shared/PanelElements/FlexRightPanel";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useTheme } from "@mui/material/styles";

import { Dlog } from "./../../../../utils/Dlog";

import HttpContext from "../../../../contexts/HTTP/HttpContext";
import KioskContext from "../../../../contexts/Kiosk/KioskContext";

import { useNavigate } from "react-router-dom";

let env = require("./../../../../env");

export default function HorizontalLayout(props) {
  const {
    setKioskkeyFunc,
    nextFunc,
    loading,
    errorMessage,
    isNotMobile,
    kioskkey
  } = props;

  const theme = useTheme();

  return (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      style={kioskstyles.GridFullHeightFix}
    >
      <FlexLeftPanel isNotMobile={isNotMobile}>
        <div style={kioskstyles.flexLeftPanelContents}>
          <div style={kioskstyles.flexParentPanel}>
            <div style={kioskstyles.flexPanelLayoutB_TopContainer}>
              <Logo />
            </div>
            <div style={kioskstyles.flexPanelLayoutB_MidContainer}>
              <div></div>
            </div>
            <div style={kioskstyles.flexPanelLayoutB_BotContainer}>
              <LanguageButtons />
            </div>
          </div>
        </div>
      </FlexLeftPanel>
      <FlexRightPanel isNotMobile={isNotMobile}>
        <div style={kioskstyles.flexRightPanelContents}>
          <div style={kioskstyles.flexParentPanel}>
            <div style={kioskstyles.flexPanelLayoutB_TopContainer}>
              <SubtitleWrapper isNotMobile={isNotMobile}>
                <Translate value="application.configuration.configuration" />
              </SubtitleWrapper>
            </div>
            <div style={kioskstyles.flexPanelLayoutB_MidContainer}>
              <Typography
                variant="h5"
                style={{ fontSize: "clamp(0.6rem, 2vw, 1.6rem)" }}
              >
                <Translate value="application.configuration.enterkey" />
              </Typography>
              <TextField
                id="KioskKey"
                label=""
                value={kioskkey}
                onChange={event => {
                  setKioskkeyFunc(event.target.value);
                }}
              />
              <Typography
                variant="body2"
                class="fontSecondaryText"
                style={{
                  color: "red",
                  marginTop: theme.spacing(0.5),
                  fontSize: "clamp(12px, 1.3vw, 20px)",
                  textAlign: "center"
                }}
              >
                {errorMessage}
              </Typography>
            </div>
            <div style={kioskstyles.flexPanelLayoutB_BotContainer}>
              {/* DEBUG BUTTONS */}
              {/* <Button
                variant="contained"
                disabled={loading}
                onClick={navigatePrinterDebug}
                style={kioskstyles.textButtons}
              >
                <span class="fontSecondaryText">
                  <Translate value="application.configuration.printerdebug" />
                </span>
              </Button> */}
              {/* END DEBUG BUTTONS */}

              <Button
                variant="contained"
                disabled={loading}
                onClick={nextFunc}
                style={{ ...kioskstyles.textButtons, marginBottom: "0px" }}
              >
                <span class="fontSecondaryText">
                  <Translate value="application.next" />
                </span>
              </Button>
            </div>
          </div>
        </div>
      </FlexRightPanel>
    </Grid>
  );
}
