import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { Route, Routes } from "react-router-dom";
import "../../index.css";

import { Grid, IconButton, Menu, MenuItem, Typography } from "@mui/material";

import { Menu as MenuIcon } from "@mui/icons-material";
import Notifications from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import Settings from "@mui/icons-material/Settings";
import AccountCircle from "@mui/icons-material/AccountCircle";

import Navigation from "../Navigation/Navigation";

import Home from "../../components/Home/Home";
import Kiosks from "../../components/Home/Kiosks";
import APIKeys from "../../components/APIKeys/APIKeys";
import SyncUpdateLogs from "../../components/Home/SyncUpdateLogs";
// import SocketMonitor from "../../components/Home/SocketMonitor";
import SocketMonitorAPI from "../../components/Home/SocketMonitorAPI";

import HttpContext from "../../contexts/HTTP/HttpContext";
import MobileContext from "../../contexts/Mobile/MobileContext";

import { useTheme } from "@mui/material/styles";

let env = require("./../../env.js");

function Dashboard() {
  const theme = useTheme();
  const { get } = useContext(HttpContext);
  const { isMobile } = useContext(MobileContext);

  const [showNavigation, setShowNavigation] = useState(!isMobile);

  const [accountMenuAnchor, setAccountMenuAnchor] = useState(null);
  const [notificationsMenuAnchor, setNotificationsMenuAnchor] = useState(null);

  const [notifications, setNotifications] = useState([]);

  const logout = async () => {
    await get("/logout");

    localStorage.removeItem("accessToken");
    window.location.href = "/login";
  };

  return (
    <Grid
      style={{
        margin: 0,
        padding: 0,
        minWidth: "100%",
        minHeight: "100%",
        display: "flex",
        flexDirection: "column"
      }}
    >
      <Grid
        style={{
          margin: 0,
          minWidth: "100%",
          padding: theme.spacing(2),
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between"
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            aria-label="toggle navigation menu"
            onClick={() => setShowNavigation(!showNavigation)}
            style={{ marginRight: theme.spacing(2) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h4">Consignaction Kiosk</Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            aria-label="toggle notifications"
            onClick={e => setNotificationsMenuAnchor(e.currentTarget)}
            style={{
              marginRight: theme.spacing(2)
            }}
            className={notifications.length > 0 ? "pulsating-icon" : ""}
          >
            {notifications.length > 0 ? (
              <NotificationsActiveIcon style={{ color: "white" }} />
            ) : (
              <Notifications />
            )}
          </IconButton>
          <Menu
            anchorEl={notificationsMenuAnchor}
            open={Boolean(notificationsMenuAnchor)}
            onClose={() => {
              setNotificationsMenuAnchor(null);
              setTimeout(() => {
                setNotifications([]);
              }, 100);
            }}
          >
            {notifications.length > 0 &&
              notifications.map(notification => {
                return <MenuItem>{notification}</MenuItem>;
              })}
            {notifications.length == 0 && (
              <MenuItem>No new notifications!</MenuItem>
            )}
          </Menu>
          <IconButton
            aria-label="toggle settings"
            onClick={e => {
              e.preventDefault();
            }}
            style={{ marginRight: theme.spacing(2) }}
          >
            <Settings />
          </IconButton>
          <IconButton
            aria-label="toggle account"
            onClick={e => setAccountMenuAnchor(e.currentTarget)}
            style={{ marginRight: theme.spacing(2) }}
          >
            <AccountCircle />
          </IconButton>
          <Menu
            anchorEl={accountMenuAnchor}
            open={Boolean(accountMenuAnchor)}
            onClose={() => setAccountMenuAnchor(null)}
          >
            <MenuItem onClick={logout}>Logout</MenuItem>
          </Menu>
        </div>
      </Grid>
      <Grid
        style={{
          margin: 0,
          padding: 0,
          display: "flex",
          flexDirection: "row",
          minWidth: "100%"
        }}
      >
        <Navigation
          visible={showNavigation}
          handleClose={() => setShowNavigation(false)}
        />
        <Routes>
          {JSON.parse(env.REACT_APP_API_KEYS_ENABLED) && (
            <Route path="api" element={<APIKeys />} />
          )}
          <Route path="/" element={<Home />} />
          <Route path="/Kiosks" element={<Kiosks />} />
          <Route path="/SyncUpdateLogs" element={<SyncUpdateLogs />} />
          {/* <Route path="/SocketMonitor" element={<SocketMonitor />} /> */}
          <Route path="/SocketMonitorAPI" element={<SocketMonitorAPI />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </Grid>
    </Grid>
  );
}

export default Dashboard;
