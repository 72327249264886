import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CancelIcon from "@mui/icons-material/Cancel";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { Translate, Localize, getLocale } from "react-i18nify";

import StepperButton from "./StepperButton_V";

import { kioskstyles } from "./../../../../kioskstyles";

import { StepConnector } from "@mui/material";

import { useTheme, createTheme, ThemeProvider } from "@mui/material/styles";
import { colors } from "@mui/material";

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = mainColor => augmentColor({ color: { main: mainColor } });

const steps = ["", "", "", ""];

// props has activeStep
export default function KioskStepper(props) {
  const navigate = useNavigate();

  const theme = useTheme();

  const vtheme = createTheme(theme, {
    primary: createColor("#000000"),
    secondary: colors.pink,
    components: {
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...{
              minWidth: "0px" //Fix for scaling button on higher res
            }
          })
        }
      },
      MuiStepIcon: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...{
              width: "2.2vh",
              height: "2.2vh",
              //border: "1px solid #444444",
              border: "0.1vh solid black",
              borderRadius: "50%",
              //opacity: 0.5,
              //backgroundColor: "white",
              color: "rgba(0,0,0,0)",
              "&.Mui-active": {
                //border: "1px solid black",
                border: "0px solid black",
                opacity: 1,
                width: "2.5vh",
                height: "2.5vh",
                "& .MuiStepIcon-text": {
                  fill: "#ffffff"
                }
              },
              "&.Mui-completed": {
                //border: "1px solid black",
                border: "0px solid black",
                opacity: 1,
                width: "2.5vh",
                height: "2.5vh",
                backgroundColor: "white"
              }
            }
          }),
          text: ({ ownerState }) => ({
            ...{
              fill: "black"
              // //fontSize: "2vh"
              // "&.Mui-active": {
              //   fill: "white"
              // }
            }
          })
        }
      },
      MuiStepConnector: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...{
              "&.Mui-disabled": {
                opacity: 0
              }
            }
          }),
          line: ({ ownerState }) => ({
            ...{
              borderColor: "black",
              "&.Mui-disabled": {
                opacity: 0
              }
            }
          })
        }
      },
      MuiStep: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...{
              paddingLeft: "2.2vw"
            }
          })
        }
      },
      MuiStepButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...{
              //margin: 0,
              //padding: 0,
              //top: "-0.5vh"
              top: "-0.2rem" //Seems to actually scale???
            }
          })
        }
      },
      MuiStepLabel: {
        styleOverrides: {
          iconContainer: ({ ownerState }) => ({
            ...{
              paddingRight: "1.25vh"
            }
          })
        }
      }
    }
  });

  const pressBack = () => {
    if (props.backUrl !== undefined) {
      navigate(props.backUrl);
    }
  };

  const pressEnd = () => {
    navigate("/language");
  };

  return (
    <div
      style={
        {
          //maxWidth: "490px", //Same as blackCornedDiv
          //width: "41vw" //TODO: use minWidth, if mobile
        }
      }
    >
      <Box
        sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}
      >
        <Button
          variant="contained"
          disabled={props.backUrl == undefined}
          onClick={pressBack}
          style={{
            borderRadius: "1vi",
            textTransform: "capitalize",
            //marginRight: "1.25vw",
            padding: "0.5vw 2vw 0.5vw 1.5vw",
            height: "3.9vh",
            width: "19vw",
            minWidth: "0vh"
          }}
        >
          <KeyboardBackspaceIcon
            style={{ marginRight: "1.9vw", fontSize: "2.3vh" }}
          />
          {/* &nbsp; &nbsp; */}
          <span class="fontSecondaryText" style={{ fontSize: "1.3vh" }}>
            <Translate value="application.back" />
          </span>
        </Button>
        <ThemeProvider theme={vtheme}>
          <Stepper
            activeStep={props.activeStep}
            style={{
              width: "32vw",
              paddingLeft: "6.5vw",
              paddingRight: "6.5vw"
            }}
            connector={<StepConnector />}
          >
            {steps.map((label, index) => (
              <Step
                key={index}
                style={{ paddingLeft: "2.2vw", paddingRight: "0vw" }}
              >
                {/* <StepButton
                color="inherit"
                style={{ height: "1.9vh", width: "1.9vh" }}
              >
                {label}
              </StepButton> */}
                <StepperButton
                  color="inherit"
                  style={{ height: "1.9vh", width: "1.9vh" }}
                >
                  {label}
                </StepperButton>
                {/* <div color="inherit" style={{ height: "1.9vh", width: "1.9vh" }}>
                <span>{label}</span>
              </div> */}
              </Step>
            ))}
          </Stepper>
        </ThemeProvider>
        <Button
          variant="contained"
          onClick={pressEnd}
          style={{
            borderRadius: "1vi",
            textTransform: "capitalize",
            padding: "0.5vw 2vw 0.5vw 1.5vw",
            height: "3.9vh",
            width: "19vw",
            minWidth: "0vh"
          }}
        >
          <CancelIcon style={{ marginRight: "1.9vw", fontSize: "2.3vh" }} />
          <span class="fontSecondaryText" style={{ fontSize: "1.3vh" }}>
            <Translate value="application.end" />
          </span>
        </Button>
      </Box>
    </div>
  );
}
