import React from "react";
import "./../../../../font.css";
import { kioskstyles } from "./../../../../kioskstyles";
let env = require("./../../../../env");

// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)
// https://reactjs.org/docs/refs-and-the-dom.html#refs-and-function-components
function VersionDisplay(props) {
  return <></>; //Diable VersionDisplay for inner screens

  return (
    //These Style should match the styles form the idle timer
    <div
      style={{
        position: "absolute",
        top: "0.1vh",
        left: "1vw",
        color: "white",
        display: "flex"
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "parent",
          fontSize: "clamp(12px, 2.5vh, 100px)"
        }}
      >
        {/* Idle: */}
      </div>
      <div
        id="idleTimerCircle"
        className="fontSecondaryText"
        style={{
          marginLeft: "5px",
          width: "3vh",
          height: "3vh",
          fontSize: "1.5vh"
          //borderRadius: "50px"
          //backgroundColor: "white"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
          }}
        >
          <span style={{ color: "white" }}>{env.REACT_APP_VERSION}</span>
        </div>
      </div>
    </div>
  );
}

//Depricated
export default VersionDisplay;
