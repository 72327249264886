import React from "react";
import "./../../../../font.css";
import { kioskstyles } from "./../../../../kioskstyles";
let env = require("./../../../../env");

// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)
// https://reactjs.org/docs/refs-and-the-dom.html#refs-and-function-components
function VersionDisplay(props) {
    return (
      //These Style should match the styles form the idle timer
    <div
      style={{
        position: "absolute",
        bottom: "1vh",
        left: "1vw",
        color: "white",
        display: "flex"
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "parent",
          fontSize: "clamp(12px, 2.5vh, 100px)"
        }}
      >
        {/* Idle: */}
      </div>
      <div
        id="idleTimerCircle"
        class="fontSecondaryText"
        style={{
          marginLeft: "5px",
          width: "clamp(18px, 5vh, 100px)",
          height: "clamp(18px, 5vh, 100px)",
          fontSize: "clamp(12px, 2.5vh, 100px)"
          //borderRadius: "50px"
          //backgroundColor: "white"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
          }}
        >
          <span style={{ color: "white" }}>{env.REACT_APP_VERSION}</span>
        </div>
      </div>
    </div>
  );
}

//Depricated
export default VersionDisplay;
