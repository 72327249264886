// import React from "react";

// const KioskContext = React.createContext({});

// export const KioskProvider = KioskContext.Provider;
// export const KioskConsumer = KioskContext.Consumer;
// export default KioskContext;

import React, { useState } from "react";

import { Dlog } from "./../../utils/Dlog";

const KioskContext = React.createContext({
  customerFirstName: "",
  customerLastName: "",
  customerID: "",
  setCustomerNameAndID: () => {},
  numBags: "",
  setNumBags: () => {},
  numLabels: "",
  setLabels: () => {},
  labelsList: [],
  setlabelsList: () => {},
  sessionID: "",
  setSessionID: () => {},
  setAll: () => {}, //Hack to allow all data to be saved.
  customerReset: () => {}
});

export const KioskProvider = props => {
  const setCustomerNameAndID = (name, id) => {
    Dlog("KioskProvider setCustomerNameAndID prechange state", state);
    setState({ ...state, customerName: name, customerID: id });
  };

  const setNumBags = bags => {
    Dlog("KioskProvider setNumBags prechange state", state);
    setState({ ...state, numBags: bags });
  };

  const setLabels = labels => {
    Dlog("KioskProvider setLabels prechange state", state);
    setState({ ...state, numLabels: labels });
  };

  const setlabelsList = list => {
    Dlog("KioskProvider setlabelsList prechange state", state);
    setState({ ...state, labelsList: list });
  };

  const setSessionID = id => {
    Dlog("KioskProvider setSessionID prechange state", state);
    setState({ ...state, sessionID: id });
  };

  const setAll = (
    customerFirstName,
    customerLastName,
    customerID,
    numBags,
    numLabels,
    labelsList,
    sessionID
  ) => {
    Dlog("KioskProvider setAll prechange state", state);
    setState({
      ...state,
      customerFirstName: customerFirstName,
      customerLastName: customerLastName,
      customerID: customerID,
      numBags: numBags,
      numLabels: numLabels,
      labelsList: labelsList,
      sessionID: sessionID
    });
  };

  const customerReset = labels => {
    setState({
      ...state,
      customerFirstName: null,
      customerLastName: null,
      customerID: null,
      numBags: null,
      numLabels: null,
      labelsList: [],
      sessionID: ""
    });
  };

  const initState = {
    customerFirstName: "",
    customerLastName: "",
    customerID: "",
    setCustomerNameAndID: setCustomerNameAndID,
    numBags: "",
    setNumBags: setNumBags,
    numLabels: "",
    setLabels: setLabels,
    labelsList: [],
    setlabelsList: setlabelsList,
    sessionID: "",
    setSessionID: setSessionID,
    setAll: setAll,
    customerReset: customerReset
  };


  const [state, setState] = useState(initState);

  return (
    <KioskContext.Provider value={state}>
      {props.children}
    </KioskContext.Provider>
  );
};
export const KioskConsumer = KioskContext.Consumer;
export default KioskContext;
