import React, { useEffect, useState } from "react";
import "./../../../../font.css";
import { getLocale, I18n } from "react-i18nify";

let env = require("./../../../../env");

// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)
// https://reactjs.org/docs/refs-and-the-dom.html#refs-and-function-components
function SplashImage(props) {
  return <I18n render={() => <SplashImageHelper />} />;
}

function SplashImageHelper(props) {
  switch (getLocale()) {
    case "en":
      return (
        <img
          src="/img/english-splash.png"
          // objectFit="contain"
          style={{
            height: "auto",
            width: "auto",
            maxWidth: "100%",
            maxHeight: "100%"
          }}
          alt="Splash Image"
        />
      );
    default:
      //French
      return (
        <img
          src="/img/french-splash.png"
          // objectFit="contain"
          style={{
            height: "auto",
            width: "auto",
            maxWidth: "100%",
            maxHeight: "100%"
          }}
          alt="Splash Image"
        />
      );
  }
}

export default SplashImage;
