import React from "react";
import QRCode from "react-qr-code";

// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)
// https://reactjs.org/docs/refs-and-the-dom.html#refs-and-function-components
function BagtagLabel(props) {
  const { customerName, label } = props;

  return (
    <div
      style={{
        backgroundColor: "white",
        margin: "5px",
        padding: "7px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%"
      }}
    >
      <div
        style={{
          height: "auto",
          margin: "5px auto",
          maxWidth: 164,
          width: "100%"
        }}
      >
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={"SomeValue"}
          viewBox={`0 0 256 256`}
        />
      </div>
      <div
        style={{
          height: "auto",
          margin: "5px auto",
          paddingLeft: "23px",
          //maxWidth: 164,
          width: "100%",
          fontSize: "larger"
        }}
      >
        {/* <div style={{ fontStyle: "italic" }}>{customerName}</div> */}
        <div style={{ fontWeight: "bold" }}>{label}</div>
      </div>
      <p style={{ pageBreakAfter: "always" }}>&nbsp;</p>
    </div>
  );
}

export default BagtagLabel;
