export default function formatFirstNameLastInitial(firstName, lastName) {
  let lastInitial = "";

  if (lastName !== null && lastName.length >= 1) {
    lastInitial = lastName.substring(0, 1);
    return firstName + " " + lastInitial;
  }

  return firstName;
}
