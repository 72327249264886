import * as React from "react";

import { Button, Grid, Typography, IconButton, TextField } from "@mui/material";

import PhoneInput, {
  isPossiblePhoneNumber,
  formatPhoneNumber
} from "react-phone-number-input";

const steps = ["", "", "", ""];

// props has activeStep
export default function PhoneDisplay(props) {
  // console.log(
  //   "PhoneDisplay",
  //   "props.value",
  //   props.value,
  //   formatPhoneNumber("+1" + props.value)
  // );

  const telephonebuttonpress = event => {};

  const customPhoneDisplay = number => {
    let display = number.padEnd(10, "_");

    // display =
    //   "(" +
    //   display.substring(0, 3) +
    //   ") " +
    //   display.substring(3, 6) +
    //   "-" +
    //   display.substring(6, 10);

    display =
      "" +
      display.substring(0, 3) +
      "-" +
      display.substring(3, 6) +
      "-" +
      display.substring(6, 10);
    return display;
  };

  return (
    <div>
      <Typography
        variant="h4"
        style={{
          //fontSize: "clamp(18px, 6vh, 2.8em)"
          fontSize: "5vh",
          whiteSpace: "nowrap",
          // fontSize: "3em"
          width: "100%"
        }}
      >
        <center>{customPhoneDisplay(props.value)}</center>
      </Typography>
    </div>
  );
}
