import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  Button,
  Grid,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  OutlinedInput,
  FormHelperText
} from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useTheme } from "@mui/material/styles";

import { EMAIL_REGEX, PASSWORD_REGEX } from "../../constants";

import HttpContext from "../../contexts/HTTP/HttpContext";

const ERRORS = {
  400: "An error occurred while processing your registration",
  409: "An account for this email already exists"
};

function Register({ authenticate }) {
  const theme = useTheme();
  const { post } = useContext(HttpContext);

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [registerError, setRegisterError] = useState("");

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is a required field")
        .matches(EMAIL_REGEX, "Email format invalid"),
      password: Yup.string()
        .required("Password is a required field")
        .max(32, "Password can contain at most 32 characters")
        .matches(
          PASSWORD_REGEX,
          "Password must contain at least one uppercase character, one lowercase character, and one number"
        )
    }),
    onSubmit: async values => {
      setLoading(true);
      setShowPassword(false);

      const res = await post("/register", values);
      if (res.status >= 400) {
        setRegisterError(ERRORS[res.status]);
      } else {
        authenticate(res);
        setRegisterError("");
      }

      setLoading(false);
    }
  });

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      formik.handleSubmit();
    }
  };

  return (
    <Grid
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "480px",
        margin: "auto"
      }}
    >
      <Typography variant="h4" style={{ margin: theme.spacing(2) }}>
        Register for Kiosk
      </Typography>
      <Typography style={{ marginBottom: theme.spacing(2) }}>
        Register an account or <Link to={"/login"}>log in</Link>
      </Typography>
      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <TextField
          variant="outlined"
          label="First Name"
          style={{
            marginRight: theme.spacing(0.5),
            marginBottom: theme.spacing(0.75),
          }}
        />
        <TextField
          variant="outlined"
          label="Last Name"
          style={{
            marginLeft: theme.spacing(0.5),
            marginBottom: theme.spacing(0.75),
          }}
        />
      </div> */}
      <TextField
        fullWidth
        style={{ margin: theme.spacing(0.75) }}
        variant="outlined"
        id="email"
        name="email"
        label="Email"
        value={formik.values.email}
        onChange={formik.handleChange}
        disabled={loading}
        error={!_.isEmpty(formik.errors.email)}
        helperText={formik.errors.email}
        onKeyDown={handleKeyDown}
      />
      <FormControl
        fullWidth
        variant="outlined"
        disabled={loading}
        style={{ margin: theme.spacing(0.75) }}
        error={!_.isEmpty(formik.errors.password)}
      >
        <InputLabel htmlFor="password">Password</InputLabel>
        <OutlinedInput
          id="password"
          name="password"
          label="Password"
          value={formik.values.password}
          type={showPassword ? "text" : "password"}
          onChange={formik.handleChange}
          onKeyDown={handleKeyDown}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={e => {
                  e.preventDefault();
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText>{formik.errors.password}</FormHelperText>
      </FormControl>

      <Typography variant="body2" style={{ margin: theme.spacing(1) }}>
        By registering an account you agree to our Terms and Conditions.
      </Typography>

      <Typography variant="body2" style={{ color: "red" }}>
        {registerError}
      </Typography>

      <Button
        fullWidth
        variant="contained"
        disabled={loading}
        onClick={formik.handleSubmit}
        style={{ margin: theme.spacing(2) }}
      >
        Register
      </Button>
    </Grid>
  );
}

export default Register;
