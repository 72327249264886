import React, { useContext, useState } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useFormik } from "formik";

import {
  Button,
  Grid,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  OutlinedInput
} from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useTheme } from "@mui/material/styles";

import HttpContext from "../../contexts/HTTP/HttpContext";

const ERRORS = {
  400: "An error occurred while processing your login",
  401: "Invalid credentials provided",
  404: "Invalid credentials provided"
};

function Register({ authenticate }) {
  const theme = useTheme();
  const { post } = useContext(HttpContext);

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [loginError, setLoginError] = useState("");

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    onSubmit: async values => {
      setLoading(true);
      setShowPassword(false);

      const res = await post("/login", values);
      if (res.status >= 400) {
        setLoginError(ERRORS[res.status]);
      } else {
        authenticate(res);
        setLoginError("");
      }

      setLoading(false);
    }
  });

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      formik.handleSubmit();
    }
  };

  return (
    <Grid
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "480px",
        margin: "auto"
      }}
    >
      <Typography variant="h4" style={{ margin: theme.spacing(2) }}>
        Login to Consignaction Kiosk
      </Typography>{" "}
      <Typography style={{ marginBottom: theme.spacing(2) }}>
        Login to your account
      </Typography>
      <TextField
        fullWidth
        style={{ margin: theme.spacing(0.75) }}
        variant="outlined"
        id="email"
        name="email"
        label="Email"
        value={formik.values.email}
        onChange={formik.handleChange}
        disabled={loading}
        onKeyDown={handleKeyDown}
      />
      <FormControl
        fullWidth
        variant="outlined"
        disabled={loading}
        style={{ margin: theme.spacing(0.75) }}
      >
        <InputLabel htmlFor="password">Password</InputLabel>
        <OutlinedInput
          id="password"
          name="password"
          label="Password"
          value={formik.values.password}
          type={showPassword ? "text" : "password"}
          onChange={formik.handleChange}
          onKeyDown={handleKeyDown}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                disabled={loading}
                onClick={e => {
                  e.preventDefault();
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <Typography
        variant="body2"
        style={{ color: "red", marginTop: theme.spacing(0.5) }}
      >
        {loginError}
      </Typography>
      <Button
        fullWidth
        variant="contained"
        disabled={loading}
        onClick={formik.handleSubmit}
        style={{ margin: theme.spacing(2) }}
      >
        Login
      </Button>
    </Grid>
  );
}

export default Register;
