import React from "react";
import BagtagLabel from "./BagtagLabel";
import { printstyles } from "./../../../printstyles";
import "./../../../print.css";

// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)
// https://reactjs.org/docs/refs-and-the-dom.html#refs-and-function-components
export const LabelsToPrint = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} className="printOnly">
      {props.labelsList.map(d => (
        <BagtagLabel customerName={props.customerName} label={d} />
      ))}
    </div>
  );
});

//   '@media screen {display: none;}'
//   {

//   display: "flexbox",
//   flexDirection: "row",
//   width: "100%"
// }
